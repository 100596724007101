(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

//global.$ = window.jQuery;
var botons = require('./modules/botons');

//$(() => {
var DEBUG = true; // Set to false to disable console methods output in all modules

if (!DEBUG) {
  if (!window.console) window.console = {};
  var methods = ['log', 'debug', 'warn', 'info'];
  methods.forEach(function (method) {
    console[method] = function () {};
  });
}
console.log('🏎 Initializing website…');

// Here goes your JS

botons();

//window.history.scrollRestoration = "manual";

console.log('🏁 Website initialized!');
//});

},{"./modules/botons":2}],2:[function(require,module,exports){
'use strict';

module.exports = function () {
  if ($("#idiomes").length) {
    $(".btIdiomes").click(function (e) {
      e.preventDefault();
      $("body").toggleClass("menuOpen");
    });
    $(".btTancar").click(function (e) {
      e.preventDefault();
      $("body").toggleClass("menuOpen");
    });
  }
};

},{}]},{},[1]);
